import CoinBoxBase from './CoinBoxBase';

class CoinBoxBluetooth extends CoinBoxBase {
    constructor(bluetoothDeviceManager, audioPlayer) {
        super(audioPlayer);
        this.manager = bluetoothDeviceManager;
        this.nextCoin = true;
        this.manager.onNotification(this.handleNotification.bind(this));
        /*this.outputCommandColor = 0;
        this.outputCommandSend = false;*/
    }

    async handleNotification(partId, commandType, sequencePositionOrColorSlot, additionalData1, additionalData2, additionalData3, additionalData4, additionalData5, additionalData6) {
        /*        console.log(`COINBOX received:
                    Part ID: ${partId},
                    Command/Status Type: ${commandType},
                    Sequence Position/Color or Slot: ${sequencePositionOrColorSlot},
                    Additional Data1: ${additionalData1},
                    Additional Data1: ${additionalData2},
                    Additional Data1: ${additionalData3}`);
        */
        if (commandType === 0x02) {
            if (partId === 0x03) { // color sensor
                if (sequencePositionOrColorSlot === 95) {
                    this.coinDetected();
                } else if (this.nextCoin) {
                    this.addToInputQueue(sequencePositionOrColorSlot, Date.now(), additionalData4);
                    this.nextCoin = false;
                    console.log('Coin color:', sequencePositionOrColorSlot, this.colorNames[sequencePositionOrColorSlot], additionalData1, additionalData2, additionalData3, this.colorNames[additionalData4]);
                }
            } else if (partId === 0x01) {  // top 
                if (sequencePositionOrColorSlot === 99) {
                    this.nextCoin = true;
                    console.log('Coin eating completed');
                }
            } else if (partId === 0x02) {   // bottom
                if (sequencePositionOrColorSlot === 1) {
                    this.outputReady = true;
                    console.log('Output queue ready to process new events');
                } else if (sequencePositionOrColorSlot === 4) {
                    this.executeOutputCoinEvent();
                }
            } else if (partId === 0x0A) { // auto eat
                this.autoEat = (sequencePositionOrColorSlot === 0x01);
                console.log('Auto eat enabled:', this.autoEat);
            }
        } else if (commandType === 0x06) {

            console.log('vision');
            console.log(partId, commandType, sequencePositionOrColorSlot, additionalData1, additionalData2, additionalData3, additionalData4, additionalData5, additionalData6);
            let visionEvent = null;
            if (sequencePositionOrColorSlot === 5) {
                visionEvent = { 'eventId': sequencePositionOrColorSlot, 'redCreatures': additionalData1, 'redSpell': additionalData2, 'blueCreatures': additionalData3, 'blueSpell': additionalData4, 'blueLife': additionalData5, 'redLife': additionalData6 }
            } else {
                visionEvent = { 'eventId': sequencePositionOrColorSlot, 'player': additionalData1, 'cardtype': additionalData2, 'cardId': additionalData3 }
            }

            this.visionEventRecieved(visionEvent);
        } else if (partId === 0x09) { // coinbox
            if (commandType === 0x03) {
                this.boxBootupCompleted = true;
                console.log('Coinbox bootup completed');
            }
            if (commandType === 0x04) {
                console.log('Heartbeat received');
                if (sequencePositionOrColorSlot === 0) {
                    this.manager.heartbeat();
                }
            }
        }
    }

    async executeOutputCoin() {
        if (this.outputEvent === null) return;
        let commandSend = false;
        while (!commandSend) {
            let trayNumber;
            if (typeof this.outputEvent.outputTray === 'string') {
                trayNumber = parseInt(this.outputEvent.outputTray.slice(-1), 10);
            } else {
                trayNumber = this.outputEvent.outputTray;
            }
            commandSend = await this.manager.sendCommandWithTray(2, this.outputEvent.color, trayNumber);
            if (commandSend) {
                this.outputReady = false;
            } else {
                await this.wait(5);
            }
        }
    }

    async waitForSetupCompletion() {
        while (!this.boxBootupCompleted) {
            await this.wait(100);
        }
    }

    async setAutoEat(enabled) {
        if (this.autoEat === enabled) return;
        await this.manager.sendCommand(10, enabled ? 0x01 : 0x00);
        while (this.autoEat !== enabled) {
            await this.wait(50);
        }
    }

    async setColorMode(colorMode) {
        await this.manager.sendCommand(0x0F, colorMode);
    }

}

export default CoinBoxBluetooth;
