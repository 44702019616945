class SeededRandom {
    constructor(seed) {
        this.seed = seed;
        this.m = 0x80000000; // 2**31
        this.a = 1103515245;
        this.c = 12345;
    }

    next() {
        this.seed = (this.a * this.seed + this.c) % this.m;
        return this.seed / (this.m - 1);
    }

    shuffle(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(this.next() * (i + 1)); 
            [array[i], array[j]] = [array[j], array[i]]; 
        }
        return array;
      }
}

export default SeededRandom;