import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyD2PuWFxlS5AzjtURgUspqjhJx6cY8yF4M",
  authDomain: "coinbox-c3d4c.firebaseapp.com",
  projectId: "coinbox-c3d4c",
  storageBucket: "coinbox-c3d4c.appspot.com",
  messagingSenderId: "100473830283",
  appId: "1:100473830283:web:061aea0580d35737458d8e",
  measurementId: "G-LH6CYBR82H"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

export { app, firestore, auth };


