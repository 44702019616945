import React, { useState } from 'react';
import BluetoothControl from '../BluetoothControl/BluetoothControl';
import Wizards2ClientComponent from './Wizards2ClientComponent';
import { useAuth } from '../../contexts/AuthContext';

const Wizard2Main = () => {
  const { currentUser } = useAuth();
  const [CoinBox, setCoinbox] = useState(null);
  return (
    <div className="min-h-screen bg-gray-50 p-4 w-full">
      {!CoinBox &&
        <div>
          <h1 className="text-xl font-bold mb-4">Welcome, {currentUser.email}</h1>
        </div>
      }
      <BluetoothControl setCoinbox={setCoinbox} />
      {CoinBox && <Wizards2ClientComponent coinbox={CoinBox} />}
    </div>
  );
};
export default Wizard2Main;