class AudioPlayer {
    constructor(trackNames) {
        this.cache = new Map();
        this.cachePaths = new Map();
        this.tracks = {};
        trackNames.forEach((name, index) => {
            this.tracks[name] = {
                playing: "",
                priority: 99,
                currentSourceNode: null,
                silentSource: null,
                id: index + 1,
                queue: [],
                gainNode: null // Adding gain node here
            };
        });
        this.audioContext = null;
    }

    createAudioContext() {
        if (!this.audioContext) {
            this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        }
    }

    createSilentSource() {
        const buffer = this.audioContext.createBuffer(1, 1, 22050);
        const source = this.audioContext.createBufferSource();
        source.buffer = buffer;
        source.loop = true;
        
        // Ensure the gain node is connected
        const gainNode = this.audioContext.createGain();
        source.connect(gainNode);
        gainNode.connect(this.audioContext.destination);
        
        return source;
    }

    startSilentStreamsForAllTracks() {
        Object.keys(this.tracks).forEach(trackName => {
            this.startSilentStreamForTrack(trackName);
        });
        console.log("AudioPlayer: Silent streams started for all tracks.");
    }

    startSilentStreamForTrack(trackName) {
        this.createAudioContext();
        const track = this.tracks[trackName];
        if (!track) {
            console.error(`AudioPlayer error: Invalid trackName ${trackName} for starting a silent stream.`);
            return;
        }
        if (track.silentSource) {
            track.silentSource.disconnect(); // Disconnect the existing silent source for this track, if any
        }
        // Ensure a GainNode is available for the silent source as well
        if (!track.gainNode) {
            track.gainNode = this.audioContext.createGain();
            track.gainNode.gain.setValueAtTime(1.0, this.audioContext.currentTime); // Initialize with default gain
        }
        track.silentSource = this.createSilentSource();
        // Connect the silent source through the gain node to the audio context's destination
        track.silentSource.connect(track.gainNode);
        track.gainNode.connect(this.audioContext.destination);
    
        if (this.audioContext.state === 'suspended') {
            this.audioContext.resume();
        }
    
        track.silentSource.start(0);
        //console.log(`AudioPlayer: Running silent stream for track ${trackName}`);
    }
    
    async loadAndDecodeAudio(blobBase64Data) {
        // Decode base64 to binary
        const byteCharacters = atob(blobBase64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Promise((resolve, reject) => {
            this.audioContext.decodeAudioData(byteArray.buffer, resolve, reject);
        });
    }

    async stopTrack(trackName) {
        const track = this.tracks[trackName];
        if (!track) {
            console.error(`AudioPlayer error: Invalid trackName ${trackName}`);
            return;
        }
    
        // Stop the current source node if it exists
        if (track.currentSourceNode) {
            track.currentSourceNode.stop();
            track.currentSourceNode = null;
        }
    
        // Disconnect and stop the silent source if it exists
        if (track.silentSource) {
            track.silentSource.stop();
            track.silentSource.disconnect();
            track.silentSource = null;
        }
        await this.waitForTrackToEnd(track); 
    
        // Reset the playing status and queue
        track.playing = "";
        track.queue = [];
        track.priority = 99;
    
        // Reset the gain node's volume to a default value if needed
        if (track.gainNode) {
            track.gainNode.gain.setValueAtTime(1.0, this.audioContext.currentTime);
        }
    
        // Restart the silent stream for this track
        await this.startSilentStreamForTrack(trackName);
    
        //console.log(`AudioPlayer: Stopped all playback and restarted silent stream on track ${trackName}`);
    }

    async waitForTrackToEnd(track) {
        return new Promise(resolve => {
            const interval = setInterval(() => {
                if (track.playing === "") {
                    clearInterval(interval);
                    resolve();
                }
            }, 50);
        });
    }

    
    async play(text, filename, priority, trackName, callback = null) {
        await this.playPlaylist([{text, filename}], priority, trackName, callback);
    }

    async queueOrPlay(text, filename, priority, trackName, callback = null) {
        const track = this.tracks[trackName];
        if (!track) {
            console.error(`AudioPlayer error: Invalid trackName ${trackName}`);
            return;
        }
        if (track.priority !== priority || track.playing === "") {
            //console.log(`AudioPlayer: Queued ${text} on track ${trackName} with priority ${priority} (current priority: ${track.priority})`);    
            await this.play(text, filename, priority, trackName, callback);
        } else if (track.priority === priority) {
            track.queue.push({ text, filename });
            //console.log(`AudioPlayer: Queued ${text} on track ${trackName}`);    
           /* if (!track.currentSourceNode) {
                this.playNextInQueue(trackName, callback);
            }*/
        } 
    }

    async playPlaylist(playList, priority, trackName, callback = null) {
        // Ensure playlist is in the expected format [{text, filename}, ...]
        if (!Array.isArray(playList) || playList.length === 0) {
            console.error("AudioPlayer playPlaylist: playList must be a non-empty array of {text, filename} objects");
            return;
        }

        const track = this.tracks[trackName];
        if (!track || track.priority < priority) {
            console.log(`AudioPlayer playPlaylist (${priority}): Track ${trackName} is already playing a higher priority ${track.priority} sound or does not exist ${playList[0].text}`);
            return;
        }

        if (track.currentSourceNode || track.playing !== "") {
            if(track.currentSourceNode){
                track.currentSourceNode.stop();
                track.currentSourceNode = null; 
            }
            track.queue = []; // Reset the queue if interrupting current playback
            await this.waitForTrackToEnd(track); 
        }
        track.priority = priority;
        playList.forEach(item => track.queue.push(item));
        
        try {
            if (track.queue.length > 0) {
                track.playing = "-setup-";
                await this.playNextInQueue(trackName, callback);
            }
        } catch (error) {
            console.error(`AudioPlayer error: Failed to play next item in queue for ${trackName} - ${error}`);
        }
    }

    async playNextInQueue(trackName, callback) {
        if (!this.tracks[trackName].queue.length) {
            // Call the callback when the queue is empty (i.e., all sounds have been played)
            if (callback && typeof callback === 'function') {
                callback();
            }
            return;
        }
        const { text, filename } = this.tracks[trackName].queue.shift();
        try { 
            let audioBuffer;
            if (this.cache.has(text)) {
                audioBuffer = this.cache.get(text);
            } else {
                audioBuffer = await this.fetchAndDecodeAudio(filename);
                this.cache.set(text, audioBuffer);
                this.cachePaths.set(text, filename);
            }
            this.playAudioBuffer(audioBuffer, text, trackName, () => {
                this.playNextInQueue(trackName, callback);
            });
        } catch (error) {
            console.error(`Error caching audio file ${text} from ${filename}: ${error}`);
        }
    }

    async fetchAndDecodeAudio(filename) {
        const cacheBuster = `?cb=${Math.floor(Math.random() * 1000000)}`;
        const urlWithCacheBuster = `${filename}${cacheBuster}`;
        const response = await fetch(urlWithCacheBuster);
        const arrayBuffer = await response.arrayBuffer();
        return new Promise((resolve, reject) => {
            this.audioContext.decodeAudioData(arrayBuffer, resolve, reject);
        });
    }

    playAudioFile(text, trackName, onEndCallback) {
        const track = this.tracks[trackName];
        if(track.currentSourceNode) {
            track.currentSourceNode.pause(); // Stop the current audio if it's playing
        }
    
        const filename = this.cachePaths.get(text);
    
        const audio = new Audio(filename);
        audio.play();
    
        audio.onended = () => {
            this.onPlayEnded(trackName, onEndCallback);
            // No need to startSilentStreamForTrack since this approach doesn't use AudioContext
        };
    
        track.currentSourceNode = audio; // Keep track of the audio element for stopping later
        this.onPlayStarted(text, trackName);
    }
    
    setVolume(trackName, volume) {
        const track = this.tracks[trackName];
        if (!track.gainNode) {
            console.error(`AudioPlayer error: No gain node found for track ${trackName}`);
            return;
        }
        track.gainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
    }

    playAudioBuffer(audioBuffer, text, trackName, onEndCallback) {
        const track = this.tracks[trackName];
        if(track.silentSource) {
            track.silentSource.disconnect();
        }
    
        if (!track.gainNode) {
            console.error(`AudioPlayer error: No gain node found for track ${trackName}`);
            track.gainNode = this.audioContext.createGain(); 
        }
    
        const source = this.audioContext.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(track.gainNode);
        track.gainNode.connect(this.audioContext.destination);
    
        source.onended = () => {
            this.onPlayEnded(trackName, onEndCallback);
            this.startSilentStreamForTrack(trackName);
        };
        source.start(0);
        track.currentSourceNode = source;
        this.onPlayStarted(text, trackName);
    }

    async cacheAudioFiles(audioFiles) {
        const decodeAndCacheFile = async ({ name, filename }) => {
            try {
                const audioBuffer = await this.fetchAndDecodeAudio(filename);
                // Use the 'name' as the key for the cache
                this.cache.set(name, audioBuffer);
                this.cachePaths.set(name, filename);
                //console.log(`Cached audio file: ${name} from ${filename}`);
            } catch (error) {
                console.error(`Error caching audio file ${name} from ${filename}: ${error}`);
            }
        };
    
        // Process each file in parallel
        await Promise.all(audioFiles.map(decodeAndCacheFile));
    }    

    onPlayStarted(text, trackName) {
        //console.log(`Playback started for: ${text} on track ${trackName}`);
        const track = this.tracks[trackName];
        track.playing = text;
    }

    onPlayEnded(trackName, callback) {
        const track = this.tracks[trackName];
        if (track.playing !== "") {
            //console.log(`Cleaned up resources for: ${track.playing} on track ${trackName}`);
        }
        track.playing = "";
        track.priority = 99;
        track.currentSourceNode = null;
        if (callback && typeof callback === 'function') {
            callback();
        }
    }
    
}

export default AudioPlayer; 
