import React, { createContext, useState, useContext } from 'react';

const CoinBoxContext = createContext();

export const useCoinbox = () => useContext(CoinBoxContext);

export const CoinBoxProvider = ({ children }) => {
  const [coinbox1, setCoinbox1] = useState(null);
  const [coinbox2, setCoinbox2] = useState(null);

  const value = {
    coinbox1,
    setCoinbox1,
    coinbox2,
    setCoinbox2,
  };

  return <CoinBoxContext.Provider value={value}>{children}</CoinBoxContext.Provider>;
};
