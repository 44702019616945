import React from 'react';
import { AuthProvider } from './contexts/AuthContext';
import SignInWithEmail from './components/SignInWithEmail';
import Wizard2Main from './components/Wizards2/Wizards2Main';
import { useAuth } from './contexts/AuthContext';
import { CoinBoxProvider } from './contexts/CoinBoxContext';

import './App.css';

function AppWrapper() {
  const { currentUser } = useAuth();

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 w-full">
      {!currentUser ? <SignInWithEmail /> : <Wizard2Main />}
    </div>
  );
}

function App() {
  return (
    <CoinBoxProvider>
    <AuthProvider>
      <AppWrapper />
    </AuthProvider>
    </CoinBoxProvider>
  );
}

export default App;