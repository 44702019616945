import { firestore } from '../firebase/firebase';
import { collection, onSnapshot, doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';

class BluetoothDeviceManager {
  constructor() {
    this.device = null;
    this.characteristic = null;
    this.deviceId = '';
    this.deviceIdParts = Array(12).fill(null);
    this.topPartState = { slot: 0, status: '' };
    this.bottomPartState = { slot: 0, status: '' };
    this.error = '';
    this.serviceUuid = 'b72b4a7e-d088-4127-bea6-bd30553865d2';
    this.characteristicUuid = '580593fe-67f7-48e0-afd2-83fc4849a176';
    this.deviceIdParts = Array(12).fill(null);
    this.notificationCallbacks = [];
    this.config = {};
    this.processQueue = [];
    this.processesingData = false;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.commandQueue = [];
    this.sendingCommands = false;
    this.readingdata = false;
    this.sendConfigurationToArduinoOnce = false;
  }


  async connectAndGetCharacteristic() {
    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ services: [this.serviceUuid] }]
      });
      this.device = device;
      this.setupDeviceListeners(device); // Set up the listener for disconnection
      await this.connectToDevice(device);
    } catch (error) {
      console.error('Failed to connect to the Bluetooth device:', error);
      this.error = 'Failed to connect to the Bluetooth device.';
    }
  }

  setupDeviceListeners(device) {
    device.addEventListener('gattserverdisconnected', this.onDeviceDisconnected.bind(this));
  }

  async onDeviceDisconnected() {
    console.log('Device disconnected.');
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      setTimeout(() => {
        console.log('Attempting to reconnect...');
        this.connectToDevice(this.device).then(() => {
          console.log('Reconnected to device.');
        }).catch((error) => {
          console.error('Reconnection failed:', error);
          this.reconnectAttempts++;
        });
      }, this.calculateReconnectDelay());
    }
  }

  calculateReconnectDelay() {
    return Math.min(1000 * Math.pow(2, this.reconnectAttempts), 30000);
  }

  async connectToDevice(device) {
    const server = await device.gatt.connect();
    console.log('Connected to GATT server');
    const service = await server.getPrimaryService(this.serviceUuid);
    console.log('Got service:', service);
    this.characteristic = await service.getCharacteristic(this.characteristicUuid);
    console.log('Got characteristic:', this.characteristic);
    await this.subscribeToNotifications();
    //this.watchRemoteControlActions();
    this.reconnectAttempts = 0; 
  }

   onNotification(callback) {
    this.notificationCallbacks.push(callback);
   }

  async subscribeToNotifications() {
    if (!this.characteristic) return;
    try {
      await this.characteristic.startNotifications();
      console.log('Notifications started');
      this.characteristic.addEventListener('characteristicvaluechanged', this.handleCharacteristicValueChanged.bind(this));
      console.log('Subscribed to characteristic value changes');
      if(!this.sendConfigurationToArduinoOnce){
        const command = new Uint8Array([0x09, 0x04, 0x00, 0x00]);
        await this.enqueueCommand(command);
      } else {
        this.heartbeat();
      }
    } catch (error) {
      console.error('Failed to subscribe to notifications:', error);
    }
  }

  handleCharacteristicValueChanged(event) {
    this.readingdata = true;
    const dataClone = this.cloneEventData(event.target.value);
    this.processQueue.push(dataClone);
    this.readingdata = false;
    setTimeout(() => this.processData(), 0);
  }

  cloneEventData(value) {
    let data = new Uint8Array(value.byteLength);
    for (let i = 0; i < value.byteLength; i++) {
      data[i] = value.getUint8(i);
    }
    return data;
  }

  async processData() {
    //console.log('Processing data:', this.processQueue);
    if(this.processesingData) return;
    while (this.processQueue.length > 0) {
      this.processesingData = true;
      const value = this.processQueue.shift();
      if (value.length > 12) {
        console.error('Unexpected data length:', value.length);
        return;
      }
  
      const partId = value[0];
      const commandType = value[1];
      let sequencePositionOrColorSlot = 0;
      let additionalData1 = null;
      let additionalData2 = null;
      let additionalData3 = null;
      let additionalData4 = null;
      let additionalData5 = null;
      let additionalData6 = null;
      if(value.length > 2){
        sequencePositionOrColorSlot = value[2];
        if(value.length >= 10){
          additionalData1 = ((value[3] << 8) | value[4]);
          additionalData2 = ((value[5] << 8) | value[6]);
          additionalData3= ((value[7] << 8) | value[8]);
          additionalData4 = value[9];
        } else if(value.length === 6){
          additionalData1 = value[3];
          additionalData2 = value[4];
          additionalData3= value[5];
        } else if(value.length === 9){
          additionalData1 = value[3];
          additionalData2 = value[4];
          additionalData3= value[5];
          additionalData4 = value[6];
          additionalData5 = value[7];
          additionalData6= value[8];
        }
      }
      /*
      console.log(`Notification received:
        Part ID: ${partId},
        Command/Status Type: ${commandType},
        Sequence Position/Color or Slot: ${sequencePositionOrColorSlot},
        Additional Data: ${additionalData}`);
  */
      if (commandType === 0x03 && partId === 0x04 ) {
        const lastpart = sequencePositionOrColorSlot === 0 ? 0: 6;
        for (let i = 0; i < 6; i++) {
          this.deviceIdParts[i+lastpart] = String.fromCharCode(value[i + 3]);
        }
        if(sequencePositionOrColorSlot === 1){
          const deviceId = this.deviceIdParts.join('');
          this.deviceId = deviceId;
          console.log(`Received complete device ID: ${deviceId}`);
          this.checkAndCreateConfiguration(deviceId);
        }
      }
  
      this.notificationCallbacks.forEach(callback => callback(partId,commandType,sequencePositionOrColorSlot,additionalData1,additionalData2,additionalData3,additionalData4,additionalData5,additionalData6));
      /*
      if (this.deviceId !== null && commandType === 0x02 && partId === 0x03) {
        this.addColorSensorDocument(sequencePositionOrColorSlot, additionalData, additionalData2, additionalData3);
      }
      */
      await new Promise(resolve => setTimeout(resolve, 5));
    }
    this.processesingData = false;
  }

  async addColorSensorDocument(color, r, g, b) {
    const timestamp = new Date();
    const documentId = `${this.deviceId}_${timestamp.toISOString()}`;
  
    try {
      const colorSensorDocRef = doc(firestore, 'colorsensor', documentId);
      await setDoc(colorSensorDocRef, {
        timestamp: timestamp,
        deviceid: this.deviceId,
        color: color,
        r: r,
        g: g,
        b: b
      });
      console.log('Color sensor document added to Firestore with ID:', documentId);
    } catch (error) {
      console.error('Error adding color sensor document to Firestore:', error);
    }
  }

  async sendCommand(part, slotNumber) {
    const command = new Uint8Array([part, 0x01, slotNumber, 0x00]);
    this.enqueueCommand(command);
    return true;
  }

  async sendCommandWithTray(part, slotNumber, tray) {
    const command = new Uint8Array([part, 0x01, slotNumber, tray]);
    this.enqueueCommand(command);
    return true;
  }

  async heartbeat() {
    const command = new Uint8Array([0x09, 0x04, 1]);
    this.enqueueCommand(command);
  }

  async enqueueCommand(command) {
    this.commandQueue.push(command);
    if (!this.sendingCommands) {
      setTimeout(() => this.processCommandQueue(), 0);
    }
  }

  async processCommandQueue() {
    if(this.sendingCommands) return;
    while (this.commandQueue.length > 0) {
      while(this.readingdata ){
        await new Promise(resolve => setTimeout(resolve, 50));
        console.log('Waiting for reading data to complete');
      }
      this.sendingCommands = true;

      const command = this.commandQueue.shift();
      if (!this.characteristic) {
        console.error('No BLE characteristic available. Please connect to the device first.');
        return;
      }
      try {
        await this.characteristic.writeValue(command);
        console.log(`Command sent:`, command);
      } catch (error) {
        console.error('Failed to send command:', error);
        /*if (error.message.includes('GATT Server is disconnected.')) {
          await this.connectToDevice(this.device).catch(console.error);
        }*/
      }
      await new Promise(resolve => setTimeout(resolve, 5));
    }
    this.sendingCommands = false;
  }

  watchRemoteControlActions() {
    onSnapshot(collection(firestore, "remotecontrol"), (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type === "added" || change.type === "modified") {
          const { slot, processed } = change.doc.data();

          if (!processed) {
            await this.sendCommand(2, slot);
            await updateDoc(doc(firestore, `remotecontrol/${change.doc.id}`), { processed: true });
          }
        }
      });
    });
  }

  async sendConfigurationToArduino(config) {
      if (!this.characteristic) {
        console.error('No BLE characteristic available. Please connect to the device first.');
        return;
      }
  
      const commandType = 0x03; // Configuration command
      const parts = {
        top_clicker: 0x05,
        top_mover: 0x06,
        bottom_clicker: 0x07,
        bottom_mover: 0x08,
        tray_mover: 0x0E,
      };
  
      const sendConfigParts = async (partId, ...values) => {
        const command = new Uint8Array([partId, commandType, ...values]);
        await this.enqueueCommand(command);
        console.log(`Config sent for Part ${partId}: ${values.join(' ')}`);
      };

      // send movers and clickers
      for (const [key, values] of Object.entries(config)) {
        const partId = parts[key]; 
        if (partId) {
          await sendConfigParts(partId, ...values);
        }
      }
      const coin_detection_threshold = config.coin_detection_threshold;
      console.log(`Coin detection threshold: ${coin_detection_threshold}`);
      const coin_detection_threshold_data = new Uint8Array(2);
      coin_detection_threshold_data[0] = (coin_detection_threshold >> 8) & 0xFF; // High byte
      coin_detection_threshold_data[1] = coin_detection_threshold & 0xFF; // Low byte
      const coin_detection_threshold_command = new Uint8Array([0x0B, commandType, coin_detection_threshold_data[0], coin_detection_threshold_data[1]]);
      await this.enqueueCommand(coin_detection_threshold_command);

      const colors_in_slots = config.colors_in_slots;
      const colors_in_slots_1 = new Uint8Array([0x0C, commandType,0, colors_in_slots[0], colors_in_slots[1], colors_in_slots[2]]);
      const colors_in_slots_2 = new Uint8Array([0x0C, commandType,1, colors_in_slots[3], colors_in_slots[4], colors_in_slots[5]]);
      await this.enqueueCommand(colors_in_slots_1);
      await this.enqueueCommand(colors_in_slots_2);

      const sendColors = async (colornr, r, g, b, rv, gv, bv) => {
        const data_r = new Uint8Array(2);
        const data_g = new Uint8Array(2);
        const data_b = new Uint8Array(2);
        data_r[0] = (r >> 8) & 0xFF; // High byte
        data_r[1] = r & 0xFF; // Low byte
        data_g[0] = (g >> 8) & 0xFF; // High byte
        data_g[1] = g & 0xFF; // Low byte
        data_b[0] = (b >> 8) & 0xFF; // High byte
        data_b[1] = b & 0xFF; // Low byte
        const command = new Uint8Array([0x0D, commandType, colornr, data_r[0], data_r[1], data_g[0], data_g[1], data_b[0], data_b[1], rv, gv, bv]);
        await this.enqueueCommand(command);
      };

      const colors = config.colors;
      for (const [key, values] of Object.entries(colors)) {
        await sendColors(key, values[0], values[1], values[2], values[3], values[4], values[5]);
      }

      const config_complete_command = new Uint8Array([0x09, commandType]);
      await this.enqueueCommand(config_complete_command);
      this.sendConfigurationToArduinoOnce = true;
  }
  
    async checkAndCreateConfiguration(deviceId) {
      const docRef = doc(firestore, "configuration", deviceId);
      const docSnap = await getDoc(docRef);
  
      let config;
      if (!docSnap.exists()) {
        // Document does not exist, create it with default values
        config = {
          bottom_clicker: [130, 90, 50],
          bottom_mover: [150, 90, 30],
          top_clicker: [130, 90, 50],
          top_mover: [150, 90, 30],
          coin_detection_threshold: 2500,
          colors_in_slots: [0, 9, 2, 3, 4, 11],
          colors: {
            1: [100,200,300,10,10,10], 2: [200,100,50,20,10,10], 3: [50,100,200,30,10,10], 4: [200,50,100,40,10,10], 5: [100,50,200,50,10,10], 6: [50,200,100,60,10,10], 7: [100,200,300,10,10,10], 8: [200,100,50,20,10,10], 9: [50,100,200,30,10,10], 10: [200,50,100,40,10,10], 11: [100,50,200,50,10,10],
            12: [101,200,300,10,10,10], 132: [202,100,50,20,10,10], 14: [53,100,200,30,10,10], 15: [204,50,100,40,10,10], 16: [105,50,200,50,10,10], 17: [56,200,100,60,10,10], 18: [107,200,300,10,10,10], 19: [208,100,50,20,10,10], 20: [59,100,200,30,10,10], 21: [210,50,100,40,10,10], 22: [111,50,200,50,10,10]
          }
        };
        await setDoc(docRef, config);
        console.log("Document created with default configuration.");
      } else {
        // Document exists, use the existing configuration
        config = docSnap.data();
        console.log("Configuration document exists:", config);
      }
  
      this.sendConfigurationToArduino(config).catch(console.error);

      this.config = config;
    }
    

}

export default BluetoothDeviceManager; 
