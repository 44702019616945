import mitt from 'mitt';
import SeededRandom from './SeededRandom';

class CoinBoxBase {
    constructor(audioPlayer) {
        this.audioPlayer = audioPlayer;
        this.emitter = mitt();
        this.isReady = false;
        this.coinboxId = "";
        this.inputQueue = [];
        this.outputQueue = [];
        this.outputReady = true;
        this.boxBootupCompleted = false;
        this.outputEvent = null;
        this.autoEat = false;
        this.colorCounts = {
            'all': [0, 0, 0, 0, 0, 0],
            'tray0': [0, 0, 0, 0, 0, 0],
            'tray1': [0, 0, 0, 0, 0, 0],
            'tray2': [0, 0, 0, 0, 0, 0],
            'tray3': [0, 0, 0, 0, 0, 0],
        }
        this.pausedTime = null;
        this.trashColor = 0;
        this.coinsToTrash = 0;
        this.autoOutputTrash = true;
        this.nextInputId = 0;
        this.colors_in_slots = [8, 9, 0, 2, 4, 11]; // super important to be able to change this?
        this.colorNames = ['unknown', 'black', 'blue', 'brown', 'green', 'grey', 'orange', 'pink', 'purple', 'red', 'white', 'yellow'];
        this.coinDetectedTimestamp = null;
        this.cancelNextInput = false;
        this.random = new SeededRandom(Date.now());
        this.inputFromTray = 'all';
    }

    setId(id) {
        this.coinboxId = id;
    }

    coinDetected() {
        this.coinDetectedTimestamp = Date.now();
        const event = { action: 'inputcoin', timestamp: this.coinDetectedTimestamp, sender: "input" };
        this.emit('inputcoin', event);
    }


    setInputTray(tray = 'all'){
        this.inputFromTray = tray;
    }

    addToInputQueue(color, timestamp = Date.now(), trashColor = 0) {
        if (!this.autoEat) return false;
        let tray = this.inputFromTray; // this is used to choose from what tray the coin comes from - only important in some games
        const coinDetectedTimestamp = this.coinDetectedTimestamp || Date.now();
        const name = this.colorNames[color];
        // check if color is in colors_in_slots

        if (color === this.trashColor) {
            this.addCoinsToTrash(tray);
            const trashname = this.colorNames[trashColor];
            const event = { id: this.nextInputId++, 'action': 'trash', color: trashColor, tray: tray, timestamp: timestamp, colorname: trashname, detectedTimestamp: coinDetectedTimestamp, sender: "input" };
            this.emit('inputtrash', event);
            return false;
        }
        const event = { id: this.nextInputId++, 'action': 'coin', color, tray: tray, timestamp, colorname: name, detectedTimestamp: coinDetectedTimestamp, sender: "input" };
        const colorIndex = this.colors_in_slots.indexOf(color);
        this.colorCounts['all'][colorIndex] = Math.max(0, this.colorCounts['all'][colorIndex] - 1);
        if(tray !== 'all') {
            this.colorCounts[tray][colorIndex] = Math.max(0, this.colorCounts[tray][colorIndex] - 1);
        }
        if (this.cancelNextInput) {
            event.action = 'cancel';
        } else {
            this.inputQueue.push(event);
        }
        this.emit('input', event);
        //this.emit('inputcompleted', event);
        return true;
    }

    removeItemFromInputQueue(id) {
        const index = this.inputQueue.findIndex(event => event.id === id);
        if (index !== -1) {
            this.inputQueue.splice(index, 1);
            return true;
        }
        return false;
    }

    addCoinsToTrash(tray) {
        this.coinsToTrash += 1;
        this.startOutputTrash(tray);
    }

    startOutputTrash(tray) {
        if (!this.autoOutputTrash) return;
        if (this.coinsToTrash === 0) return;
        const timestamp = Date.now() + 1200;
        this.addToOutputQueue(this.trashColor, timestamp, null, tray);
    }

    trashOutputted() {
        this.coinsToTrash -= 1;
        console.log('trashOutputted', this.coinsToTrash);
    }

    sendAllInputToOutput() { // this one is not really working because of trays
        const sortedInputQueue = [...this.inputQueue].sort((a, b) => a.color - b.color);
        sortedInputQueue.forEach(event => {
            this.addToOutputQueue(event.color);
        });
        this.clearInputQueue();
    }

    useInputQueue() {
        if (this.inputQueue.length === 0) return;
        this.inputQueue = [];
        const event = { action: 'used', color: 0, timestamp: Date.now(), sender: "input" };
        this.emit('input', event);
    }

    clearInputQueue() {
        this.inputQueue = [];
        const event = { action: 'clear', color: 0, timestamp: Date.now(), sender: "input" };
        this.emit('input', event);
    }

    emit(eventName, data) {
        this.emitter.emit(eventName, data);
    }

    on(eventName, handler) {
        this.emitter.on(eventName, handler);
    }

    off(eventName, handler) {
        this.emitter.off(eventName, handler);
    }

    getAllFromInputQueue() {
        const copy = this.inputQueue.slice();
        return copy;
    }

    getAllFromOutputQueue() {
        const sortedOutputQueue = this.outputQueue.slice().sort((a, b) => a.timestamp - b.timestamp);
        return sortedOutputQueue;
    }

    addToOutputQueue(color, timestamp = Date.now(), callbackname = null, tray = 'random') {
        if (tray === 'random') {
            tray = 'tray' + (Math.random() < 0.5 ? 1 : 2);
        } 
        if(!this.isReady){
            // box is paused  we do need to modify the timestamp back to this.pausedTime from now
            timestamp = timestamp - (Date.now() - this.pausedTime);
        }
        const element = { color, timestamp, callbackName: callbackname, outputTray: tray };
        this.outputQueue.push(element);
        console.log('addToOutputQueue', element);

        return element;
    }

    async setColorMode(colorMode) {
        throw new Error("waitForSetupCompletion method must be implemented by subclass. ", colorMode);
    }

    async processOutputQueue() {
        while (this.isReady) {
            if (this.outputQueue.length > 0 && this.outputReady) {
                const now = Date.now();
                const nextEventIndex = this.outputQueue.findIndex(event => event.timestamp <= now);
                if (nextEventIndex !== -1) {
                    const nextEvent = this.outputQueue[nextEventIndex];
                    const { color, callbackName, outputTray } = nextEvent;

                    const colorIndex = this.colors_in_slots.indexOf(color);
                    this.colorCounts['all'][colorIndex] += 1;
                    this.colorCounts[outputTray][colorIndex] += 1;
                    this.outputQueue.splice(nextEventIndex, 1);
                    const name = this.colorNames[color];
                    this.outputEvent = { "action": "coin", "color": color, "outputTray": outputTray, "colorname": name, "timestamp": now, "sender": "output", "callbackName": callbackName };

                    await this.executeOutputCoin();
                }
            }
            await this.wait(10);
        }
    }

    async executeOutputCoin() {
        throw new Error("executeOutputCoin method must be implemented by subclass.");
    }

    async executeOutputCoinEvent() {
        if (!this.outputEvent) return;
        if (this.outputEvent.color === this.trashColor) this.trashOutputted();
        this.emit('output', this.outputEvent);
        const callbackName = this.outputEvent.callbackName;
        if (callbackName) {
            this.emit(callbackName, this.outputEvent);
        }
        this.outputEvent = null;
    }

    clearOutputQueue() {
        this.outputQueue = [];
    }

    getColorCount(color, tray = 'all') {
        const colorIndex = this.colors_in_slots.indexOf(color);
        return this.colorCounts[tray][colorIndex];
    }

    coinsInPlay(color, tray = 'all') {
        const coinsTotal = this.getColorCount(color, tray);
        const coinsIn = this.inputQueue.filter(coin => coin.color === color && ( coin.tray === tray)).length;
        return coinsTotal + coinsIn;
    }

    getTotalCoinsOut(tray = 'all') {
        return this.colorCounts[tray].reduce((acc, count) => acc + count, 0);
    }

    clearColorCount() {
        this.colorCounts = {
            'all': [0, 0, 0, 0, 0, 0],
            'tray0': [0, 0, 0, 0, 0, 0],
            'tray1': [0, 0, 0, 0, 0, 0],
            'tray2': [0, 0, 0, 0, 0, 0],
            'tray3': [0, 0, 0, 0, 0, 0],
        }
    }

    async waitForOutputCompletion() {
        while (!this.outputReady) {
            await this.wait(50);
        }
    }

    async pause() {
        if(!this.isReady) return;
        this.isReady = false;
        await this.setAutoEat(false);
        await this.waitForOutputCompletion();
        this.pausedTime = Date.now();
    }

    async pauseOutput(){
        if(!this.isReady) return;
        this.isReady = false;
        await this.waitForOutputCompletion();
        this.pausedTime = Date.now();
    }

    async start() {
        if (!this.boxBootupCompleted) return;
        if (this.isReady) return;
        if (this.pausedTime) {
            const timeDifference = Date.now() - this.pausedTime;
            console.log('timeDifference', timeDifference/1000);
            this.outputQueue = this.outputQueue.map(event => {

                console.log('event', event, timeDifference);

                event.timestamp += timeDifference;
                return event;
            });
            this.pausedTime = null;
        }
        this.isReady = true;
        this.processOutputQueue();
        await this.setAutoEat(true);
    }

    async wait(duration) {
        return new Promise(resolve => setTimeout(resolve, duration));
    }

    async waitForSetupCompletion() {
        throw new Error("waitForSetupCompletion method must be implemented by subclass.");
    }

    async setAutoEat(enabled) {
        throw new Error("waitForAutoEatCompletion method must be implemented by subclass.");
    }

    async visionEventRecieved(visionEvent) {
        this.emit('vision', visionEvent);
    }
}

export default CoinBoxBase;