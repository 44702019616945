import ServerlessClientBase from '../ServerlessClientBase';

class Wizards2Client extends ServerlessClientBase {
    constructor(coinbox, onGameStateChange = null, onCardStateChange = null) {
        super(coinbox);
        this.gameIsActive = false;

        this.currentCards = [];
        this.currentCard = null;

        this.secondsPerSpeed = 10;
        this.manaSpeedInMs = this.secondsPerSpeed * 800;

        this.currentCreature = null;
        this.currentState = '-';
        this.currentPlayer = 'none';
        this.creatureId = 0;
        this.counter = -1;
        this.counterTimer = null;
        this.stateChangedByCreatureAttackTimer = null;

        this.player1dead = false;
        this.player2dead = false;

        this.creatures = [];
        this.onGameStateChange = onGameStateChange;
        this.onCardStateChange = onCardStateChange;
        this.coinGeneration = {
            'player1': {
                'firstdelay': 1000,
                'delay': this.manaSpeedInMs,
                'colors': ['red', 'blue', 'green', 'purple', 'yellow'],
                'firstNumberCoins': 3,
                'index': 0,
                'tray': 'tray1'
            },
            'player2': {
                'firstdelay': 1000,
                'delay': this.manaSpeedInMs,
                'colors': ['red', 'blue', 'green', 'purple', 'yellow'],
                'firstNumberCoins': 3,
                'index': 0,
                'tray': 'tray2'
            }
        };
        this.cards = {
            'card6': { 'name': 'Yellow Mana', 'cost': ['green', 'blue', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card7': { 'name': 'Blue Mana', 'cost': ['green', 'yellow', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card8': { 'name': 'Green Mana', 'cost': ['yellow', 'blue', 'red', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card9': { 'name': 'Red Mana', 'cost': ['green', 'blue', 'yellow', 'purple'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card10': { 'name': 'Purple Mana', 'cost': ['green', 'blue', 'red', 'yellow'], 'strength': 0, 'speed': 0, 'damage': 0 },
            'card11': { 'name': 'Pixie', 'cost': ['yellow', 'yellow', 'purple'], 'strength': 1, 'speed': 1, 'damage': 1 },
            'card13': { 'name': 'Hobbit', 'cost': ['yellow', 'purple'], 'strength': 1, 'speed': 3, 'damage': 1 },
            'card12': { 'name': 'Snake', 'cost': ['green', 'green'], 'strength': 1, 'speed': 5, 'damage': 3 },
            'card14': { 'name': 'Kobold', 'cost': ['red'], 'strength': 1, 'speed': 8, 'damage': 1 },
            'card15': { 'name': 'Amoeba', 'cost': ['blue'], 'strength': 1, 'speed': 15, 'damage': 2 },
            'card16': { 'name': 'Hobgoblin', 'cost': ['blue', 'red'], 'strength': 2, 'speed': 4, 'damage': 1 },
            'card17': { 'name': 'Goblin', 'cost': ['green', 'red'], 'strength': 2, 'speed': 4, 'damage': 1 },
            'card18': { 'name': 'Imp', 'cost': ['red', 'purple'], 'strength': 2, 'speed': 4, 'damage': 1 },
            'card19': { 'name': 'Leprechaun', 'cost': ['yellow'], 'strength': 2, 'speed': 10, 'damage': 1 },
            'card22': { 'name': 'Elf', 'cost': ['green', 'green', 'yellow', 'yellow'], 'strength': 3, 'speed': 1, 'damage': 1 },
            'card21': { 'name': 'Mermaid', 'cost': ['blue', 'blue', 'yellow'], 'strength': 3, 'speed': 2, 'damage': 1 },
            'card20': { 'name': 'Naga', 'cost': ['blue', 'green', 'green'], 'strength': 3, 'speed': 4, 'damage': 2 },
            'card23': { 'name': 'Salamander', 'cost': ['green'], 'strength': 3, 'speed': 13, 'damage': 1 },
            'card25': { 'name': 'Banshee', 'cost': ['purple', 'purple', 'purple'], 'strength': 4, 'speed': 3, 'damage': 2 },
            'card28': { 'name': 'Centaur', 'cost': ['yellow', 'yellow'], 'strength': 4, 'speed': 4, 'damage': 1 },
            'card24': { 'name': 'Orc', 'cost': ['green', 'red', 'red'], 'strength': 4, 'speed': 5, 'damage': 2 },
            'card26': { 'name': 'Dwarf', 'cost': ['green', 'yellow'], 'strength': 4, 'speed': 6, 'damage': 1 },
            'card29': { 'name': 'Werewolf', 'cost': ['green', 'green', 'red', 'red'], 'strength': 5, 'speed': 2, 'damage': 1 },
            'card27': { 'name': 'Harpie', 'cost': ['blue', 'blue', 'purple'], 'strength': 5, 'speed': 3, 'damage': 1 },
            'card30': { 'name': 'Vampire', 'cost': ['red', 'purple', 'purple', 'purple'], 'strength': 5, 'speed': 3, 'damage': 2 },
            'card31': { 'name': 'Witch', 'cost': ['purple', 'purple', 'purple'], 'strength': 5, 'speed': 4, 'damage': 2 },
            'card32': { 'name': 'Unicorn', 'cost': ['yellow', 'yellow'], 'strength': 5, 'speed': 5, 'damage': 1 },
            'card36': { 'name': 'Elementalist', 'cost': ['blue', 'blue', 'blue'], 'strength': 6, 'speed': 3, 'damage': 1 },
            'card37': { 'name': 'Phoenix', 'cost': ['yellow', 'yellow'], 'strength': 6, 'speed': 6, 'damage': 1 },
            'card34': { 'name': 'Troll', 'cost': ['green', 'red', 'red'], 'strength': 6, 'speed': 8, 'damage': 2 },
            'card33': { 'name': 'Basilisk', 'cost': ['green', 'purple'], 'strength': 6, 'speed': 10, 'damage': 1 },
            'card35': { 'name': 'Golem', 'cost': ['purple', 'purple'], 'strength': 6, 'speed': 12, 'damage': 2 },
            'card40': { 'name': 'Minotaur', 'cost': ['yellow', 'red', 'red', 'red'], 'strength': 7, 'speed': 5, 'damage': 2 },
            'card39': { 'name': 'Mammut', 'cost': ['green', 'green', 'green', 'green', 'yellow'], 'strength': 7, 'speed': 6, 'damage': 4 },
            'card41': { 'name': 'Gargoyle', 'cost': ['red', 'purple'], 'strength': 7, 'speed': 13, 'damage': 1 },
            'card38': { 'name': 'Manticore', 'cost': ['green', 'green', 'green', 'green', 'red', 'red'], 'strength': 8, 'speed': 3, 'damage': 2 },
            'card42': { 'name': 'Griffin', 'cost': ['green', 'green', 'yellow', 'yellow'], 'strength': 8, 'speed': 4, 'damage': 1 },
            'card45': { 'name': 'Sea serpent', 'cost': ['blue', 'blue', 'blue', 'blue', 'purple'], 'strength': 8, 'speed': 6, 'damage': 3 },
            'card44': { 'name': 'Ent', 'cost': ['blue', 'green'], 'strength': 8, 'speed': 17, 'damage': 1 },
            'card43': { 'name': 'Wyvern', 'cost': ['blue', 'blue', 'blue', 'purple', 'purple'], 'strength': 9, 'speed': 3, 'damage': 1 },
            'card47': { 'name': 'Sphinx', 'cost': ['yellow', 'yellow', 'yellow', 'yellow'], 'strength': 9, 'speed': 3, 'damage': 1 },
            'card48': { 'name': 'Hydra', 'cost': ['blue', 'blue', 'blue', 'blue', 'red', 'red'], 'strength': 9, 'speed': 7, 'damage': 4 },
            'card46': { 'name': 'Giant', 'cost': ['red', 'purple', 'purple'], 'strength': 9, 'speed': 9, 'damage': 1 },
            'card49': { 'name': 'Dragon', 'cost': ['red', 'red', 'red', 'purple', 'purple'], 'strength': 10, 'speed': 11, 'damage': 3 },
            'card50': { 'name': 'Manaspire', 'cost': ['red', 'blue', 'yellow', 'purple', 'green'], 'strength': 0, 'speed': 0, 'damage': 0 },
        }

        this.transitionToState('setupGame');
    }

    transitionToState = (newState) => {
        console.log(`Transitioning from ${this.currentState} to ${newState}`);

        // Break the chain of calls by scheduling the state transition in a new event loop cycle
        setTimeout(() => {
            this.currentState = newState;

            switch (newState) {
                case 'setupGame':
                    this.setupGame();
                    break;
                case 'boardReady':
                    this.handleBoardReady();
                    break;
                case 'gameStartCountDown':
                    this.handleGameStartCountDown();
                    break;
                case 'gameStart':
                    this.handleGameStart();
                    break;
                case 'realTime':
                    this.handleRealTime();
                    break;
                case 'cardPlayed':
                    this.handleCardPlayed();
                    break;
                case 'payCost':
                    this.handlePayCost();
                    break;
                case 'creaturePlayed':
                    this.handleCreaturePlayed();
                    break;
                case 'spellPlayed':
                    this.handleSpellPlayed();
                    break;
                case 'resumeCountDown':
                    this.handleResumeCountDown();
                    break;
                case 'waitForBoardToChange':
                    this.handleWaitForBoardToChange();
                    break;
                case 'gameOver':
                    this.handleGameOver();
                    break;
                default:
                    console.log(`Unknown state: ${newState}`);
            }

            if (this.onGameStateChange) {
                this.onGameStateChange(this.currentState);
            }
        }, 1); // 1ms delay to push the execution into the next event loop cycle
    }



    setupGame() {
        this.coinbox.on('vision', this.vision);
        this.coinbox.on('inputcoin', this.coinDetected);
        this.coinbox.on('input', this.coinColorDetected);
        this.coinGeneratorSetup();
        this.coinbox.setColorMode(1);
    }

    async handleBoardReady() {
        this.coinbox.setColorMode(3);
        await this.start();
    }

    handleGameOver() {
        this.coinbox.pauseOutput();
        this.pauseAllEvents();
        this.coinbox.setColorMode(1);
        let won = 'player1';
        let lost = 'player2';
        if (this.player1dead) {
            won = 'player2';
            lost = 'player1';
        }
        const data = { 'won': won, 'lost': lost };

        this.coinbox.audioPlayer.queueOrPlay('game_won', '/audio/wizards/game_won.mp3', 10, 'notification');
        this.updateCardState(data);
    }

    handleGameStartCountDown = () => {
        // i need a count down here!
        if (this.counter === -1) {
            this.coinbox.setColorMode(1);
            this.coinbox.audioPlayer.queueOrPlay('game_start', '/audio/wizards/game_start.mp3', 10, 'background');
            this.counter = 3;
        } else {
            this.counter--;
        }

        if (this.counter === 0) {
            this.counter = -1;
            this.counterTimer = null;
            this.transitionToState('gameStart');
            return;
        }

        const number = 'number_' + this.counter;
        this.coinbox.audioPlayer.queueOrPlay(number, '/audio/wizards/' + number + '.mp3', 10, 'notification');
        this.updateCardState({ 'count': this.counter });
        this.counterTimer = this.scheduleEvent(this.handleGameStartCountDown, 1000);
    }

    async handleGameStart() {
        await this.pauseAndClear();
        await this.start();
        this.coinGeneratorStart();
        await this.pause();
        this.gameIsActive = true;
        this.transitionToState('realTime');
    }

    async handleRealTime() {
        await this.start();
        this.resumeAllEvents();
        this.coinbox.setColorMode(3);
        this.coinbox.audioPlayer.queueOrPlay('go', '/audio/wizards2/go.mp3', 10, 'notification');
        //this.coinGeneratorStart();
    }

    updateColorMode() {
        const mode = this.currentPlayer === 'player1' ? 0 : 2;
        console.log('colormode:', this.currentPlayer, mode);
        this.coinbox.setColorMode(mode);
    }

    async handleCardPlayed() {
        if (this.currentCard !== null) return;
        this.coinbox.audioPlayer.queueOrPlay('card_played', '/audio/wizards2/card_played.mp3', 10, 'opponent');
        const currentCard = this.currentCards.shift();
        this.currentCard = currentCard;
        this.currentPlayer = this.currentCard.player;

        this.updateColorMode();
        this.transitionToState('payCost');
    }

    handlePayCost() {
        const { cardId } = this.currentCard;

        // set tray for player - to make sure that wrong coins or cancel works correct
        const tray = this.coinGeneration[this.currentPlayer].tray;
        this.coinbox.setInputTray(tray);
        this.coinbox.pauseOutput();
        this.pauseAllEvents();

        // lookup cost for card
        const card = this.cards['card' + cardId];  // so the id has card in fromt in the array here
        this.currentCard.speed = card.speed;
        this.currentCard.name = card.name;
        const cost = card.cost;
        console.log('card: ', card.name, cost[0]);
        this.currentCard.manaCost = [...card.cost];
        this.currentCard.cost = [...card.cost];

        // based on the name of the card - lets lower case it and replace spaces with _
        this.currentCard.audioName = card.name.toLowerCase().replace(' ', '_');
        this.coinbox.audioPlayer.queueOrPlay(this.currentCard.audioName, '/audio/wizards2/' + this.currentCard.audioName + '.mp3', 10, 'opponent');

        // depending on the card type - we play different sounds
        if (this.currentCard.cardType === 'spell') {
            this.coinbox.audioPlayer.queueOrPlay('spell_played', '/audio/wizards2/spell_played.mp3', 10, 'opponent');
        } else if (this.currentCard.cardType === 'creature') {
            this.coinbox.audioPlayer.queueOrPlay('creature_played', '/audio/wizards2/creature_played.mp3', 10, 'opponent');
        }


        this.updateCardState(this.currentCard);
    }

    handleCreaturePlayed() {
        this.coinbox.audioPlayer.queueOrPlay(this.currentCard.audioName, '/audio/wizards2/' + this.currentCard.audioName + '.mp3', 10, 'background');
        this.coinbox.audioPlayer.queueOrPlay('ready', '/audio/wizards2/ready.mp3', 10, 'background');
        this.coinbox.useInputQueue(); // this is interesting - not 100% correct
        this.creatureId++;
        this.currentCard.creatureId = this.creatureId;
        if (this.creatureId === 50) {
            this.coinGeneration[this.currentPlayer].delay = this.coinGeneration[this.currentPlayer].delay * 0.8;
        }
        const speedInMs = this.currentCard.speed * this.secondsPerSpeed * 1000;
        console.log('creature ms ', speedInMs);
        if (speedInMs > 0) {
            const timerEvent = this.scheduleEvent(this.doCreatureAttack, speedInMs, this.creatureId);
            timerEvent.pause();
            this.currentCard.timer = timerEvent;
        }
        this.creatures.push(this.currentCard);
        this.currentCard = null;
        console.log('creature played');
        console.log(this.creatures);
        this.updateCardState([]);
        setTimeout(() => {
            this.transitionToState('resumeCountDown');
        }, 2000);
    }

    handleWaitForBoardToChange() {
        // so we look after - creature removes and life adds
        this.stateChangedByCreatureAttackTimer = null;
    }

    doCreatureAttack = (creatureId) => {
        console.log('doCreatureAttack!');
        if (this.currentCard !== null) {
            console.log('doCreatureAttack: ACTIVE CURRENTCARD!');
            return;
        }
        let creature = null;
        for (let i = this.creatures.length - 1; i >= 0; i--) {
            const card = this.creatures[i];
            if (card.creatureId === creatureId) {
                // i already now set the timer - so that i dont have to do it later - everything is paused anyways
                let speedInMs = card.speed * this.secondsPerSpeed * 1000;
                const timerEvent = this.scheduleEvent(this.doCreatureAttack, speedInMs, creatureId);
                timerEvent.pause();
                card.timer = timerEvent;
                creature = card;
                console.log('remaining:', timerEvent.remaining);
                break;
            }
        }
        if (creature === null) {
            console.log('doCreatureAttack: CREATURE NOT FOUND!');
            return;
        }

        this.currentPlayer = creature.player;
        this.updateColorMode();

        this.coinbox.audioPlayer.queueOrPlay('attack_starts', '/audio/wizards2/attack_starts.mp3', 10, 'opponent');
        this.coinbox.audioPlayer.queueOrPlay('pause_500', '/audio/wizards/pause_500.mp3', 10, 'opponent');

        this.currentCreature = creature;
        this.currentCreature.boardStateChanged = false;
        this.updateCardState(this.currentCreature);
        const audioName = creature.name.toLowerCase().replace(' ', '_');
        this.coinbox.audioPlayer.queueOrPlay(audioName, '/audio/wizards2/' + audioName + '.mp3', 10, 'opponent');
        this.coinbox.audioPlayer.queueOrPlay('creature_attacks', '/audio/wizards2/creature_attacks.mp3', 10, 'opponent');


        // i also set the tray - it should not be needed since you dont pay mana for attacks
        const tray = this.coinGeneration[this.currentPlayer].tray;
        this.coinbox.setInputTray(tray);
        this.coinbox.pauseOutput();
        this.pauseAllEvents();
        this.transitionToState('waitForBoardToChange');
    }

    handleSpellPlayed() {
        console.log("spell played: ", this.currentCard);
        this.coinbox.useInputQueue(); // this is interesting - not 100% correct

        const audioName = this.currentCard.audioName;
        this.coinbox.audioPlayer.queueOrPlay(audioName, '/audio/wizards2/' + audioName + '.mp3', 10, 'opponent');
        this.coinbox.audioPlayer.queueOrPlay('spell_active', '/audio/wizards2/spell_active.mp3', 10, 'opponent');

        // mana
        const cardId = this.currentCard.cardId;
        if (cardId >= 6 && cardId <= 10) {
            let colorName = 'unknown';
            switch (cardId) {
                case 6:
                    colorName = 'yellow';
                    break;
                case 7:
                    colorName = 'blue';
                    break;
                case 8:
                    colorName = 'green';
                    break;
                case 9:
                    colorName = 'red';
                    break;
                case 10:
                    colorName = 'purple';
                    break;
                default:
                    break;
            }
            this.coinGeneration[this.currentPlayer].colors.push(colorName);
            console.log('Mana added:', colorName, this.coinGeneration[this.currentPlayer].colors);
            this.coinbox.audioPlayer.queueOrPlay('mana_added', '/audio/wizards2/mana_added.mp3', 10, 'opponent');
        }

        // pay completed - waiting for the spell to be removed
        this.updateCardState(this.currentCard);
        console.log("spell played: ", this.currentCard.name);
    }

    handleResumeCountDown = () => {
        if (this.counter === -1) {
            this.coinbox.setColorMode(1);
            this.counter = 3;
        } else {
            this.counter--;
        }

        if (this.counter === 0) {
            this.counter = -1;
            this.counterTimer = null;
            this.transitionToState('realTime');
            return;
        }

        const number = 'number_' + this.counter;
        this.coinbox.audioPlayer.queueOrPlay(number, '/audio/wizards/' + number + '.mp3', 10, 'notification');
        this.updateCardState({ 'count': this.counter });
        this.counterTimer = this.scheduleEvent(this.handleResumeCountDown, 1000);
    }

    onCardPlayed(player, cardTypeId, cardId) {
        if (!this.gameIsActive) return;
        let cardType = '';
        let position = 0;
        switch (cardTypeId) {
            case 1:
                cardType = 'spell';
                break;
            case 2:
                cardType = 'life';
                break;
            case 11:
                position = 1;
                cardType = 'creature';
                break;
            case 12:
                position = 2;
                cardType = 'creature';
                break;
            case 13:
                position = 3;
                cardType = 'creature';
                break;
            case 14:
                position = 4;
                cardType = 'creature';
                break;
            case 15:
                position = 5;
                cardType = 'creature';
                break;
            case 21:
                position = 1;
                cardType = 'creature';
                break;
            case 22:
                position = 2;
                cardType = 'creature';
                break;
            case 23:
                position = 3;
                cardType = 'creature';
                break;
            case 24:
                position = 4;
                cardType = 'creature';
                break;
            case 25:
                position = 5;
                cardType = 'creature';
                break;
            default:
                cardType = 'unknown';
                break;
        }

        if (cardType === 'unknown') {
            return;
        }

        // cannot play a creature as spell
        if (cardType === 'spell' && cardId > 10) {
            return;
        }

        // cannot play a spell as creature
        if (cardType === 'creature' && cardId <= 10) {
            return;
        }

        if (cardType === 'life') {
            if (cardId === 20) {
                // player is dead
                if ('player1' === ('player' + player)) {
                    this.player1dead = true;
                } else {
                    this.player2dead = true;
                }
            } else {
                if ('player1' === ('player' + player)) {
                    this.player1dead = false;
                } else {
                    this.player2dead = false;
                }
            }

            this.setWaitForBoardStateToChangeTimer();
            return;
        }

        const card = { 'player': 'player' + player, 'cardType': cardType, 'cardId': cardId, 'position': position, 'timer': null, 'creatureId': -1, 'speed': 0 };
        this.currentCards.push(card);

        if (this.currentState === 'waitForBoardToChange') {
            // attack ongoing
            return;
        }

        if (this.currentCard === null) {
            this.transitionToState('cardPlayed');
        }

    }

    setWaitForBoardStateToChangeTimer() {
        if (this.currentState !== 'waitForBoardToChange') return;

        this.coinbox.audioPlayer.queueOrPlay('ai_mocamedes_hurt', '/audio/wizards/ai_mocamedes_hurt.mp3', 10, 'background');

        // should we write something here to the app?
        this.currentCreature.boardStateChanged = true;
        this.updateCardState(this.currentCreature);

        if (this.stateChangedByCreatureAttackTimer !== null) {
            this.stateChangedByCreatureAttackTimer.cancel();
            this.stateChangedByCreatureAttackTimer = null;
        }

        const timerEvent = this.scheduleEvent(this.onResumeAfterAttack, 4000);
        this.stateChangedByCreatureAttackTimer = timerEvent;
        this.updateColorMode(); // could bve cool to set it to blinking to indicate that a change has happend

    }

    onResumeAfterAttack = () => {

        this.currentCreature = null;
        if (this.player1dead || this.player2dead) {
            this.transitionToState('gameOver');
            return;
        }

        this.transitionToState('resumeCountDown');
    }

    onCardRemoved(playerId, cardTypeId, cardId) {
        if (!this.gameIsActive) return;
        let cardType = '';
        let position = 0;
        switch (cardTypeId) {
            case 1:
                cardType = 'spell';
                break;
            case 2:
                cardType = 'life';
                break;
            case 11:
                position = 1;
                cardType = 'creature';
                break;
            case 12:
                position = 2;
                cardType = 'creature';
                break;
            case 13:
                position = 3;
                cardType = 'creature';
                break;
            case 14:
                position = 4;
                cardType = 'creature';
                break;
            case 15:
                position = 5;
                cardType = 'creature';
                break;
            case 21:
                position = 1;
                cardType = 'creature';
                break;
            case 22:
                position = 2;
                cardType = 'creature';
                break;
            case 23:
                position = 3;
                cardType = 'creature';
                break;
            case 24:
                position = 4;
                cardType = 'creature';
                break;
            case 25:
                position = 5;
                cardType = 'creature';
                break;
            default:
                cardType = 'unknown';
                break;
        }

        const player = 'player' + playerId;

        // is a life card being removed?
        if (cardType === 'life') {
            // we should not do anything right now - but we should check for and event with life = 0
            return;
        }

        // is a spell being removed
        if (cardType === 'spell') {

            // did we pay for the spell?
            if (this.currentState === 'payCost') {

                // hmm i dont think we did it correctly
                this.currentCard = null;
                this.transitionToState('resumeCountDown');
                return;
            }
            if (this.currentState === 'spellPlayed') {

                // here we are waiting for the effects

                this.currentCard = null;
                this.transitionToState('resumeCountDown');
            }
            if (this.currentState === 'waitForBoardToChange') {
                for (let i = this.currentCards.length - 1; i >= 0; i--) {
                    const card = this.currentCards[i];
                    if (card.player === player && card.cardId === cardId) {
                        this.currentCards.splice(i, 1);
                        return;
                    }
                }
            }
            return;
        }

        // now we know that we are deling with a creature!

        // is this creature being played at the moment?
        if (this.currentCard !== null) {
            if (player === this.currentPlayer && this.currentCard.cardId === cardId && this.currentCard.position === position) {
                // just removing the card that was just played
                // so we need to go to payback all coins inserted and then change back
                // but first we will just cahnge state  
                // we are not doing it correct there
                this.currentCard = null;
                this.transitionToState('resumeCountDown');
                return;
            }
        }
        // is this a card in the this.currentCards array because then we need to remove it - we need to loop this.currentCards and check each card
        for (let i = this.currentCards.length - 1; i >= 0; i--) {
            const card = this.currentCards[i];
            if (card.player === player && card.cardId === cardId) {
                this.currentCards.splice(i, 1);
                return;
            }
        }

        // We are not in a play card state - so lets remove the creature
        for (let i = this.creatures.length - 1; i >= 0; i--) {
            const card = this.creatures[i];
            if (card.player === player && card.cardId === cardId) {
                this.setWaitForBoardStateToChangeTimer();
                if (card.timer !== null) {
                    card.timer.cancel();
                }
                if (card.creatureId === 50) {
                    this.coinGeneration[player].delay = this.coinGeneration[player].delay * 1.25;
                }
                this.creatures.splice(i, 1);
                break;
            }
        }



    }

    // ------- handle vision events

    vision = (event) => {
        const { eventId, player, cardtype, cardId } = event;

        switch (eventId) {
            case 0:
                // waiting for okay event! I could add a check/timeout here!
                break;
            case 1:
                if (this.currentState !== 'setupGame') return;
                this.transitionToState('boardReady');
                break;
            case 2:
                console.log('Card played');
                this.onCardPlayed(player, cardtype, cardId);
                break;
            case 3:
                console.log('Card removed');
                this.onCardRemoved(player, cardtype, cardId);
                break;
            case 4:
                if (this.currentState !== 'boardReady') return;
                this.transitionToState('gameStartCountDown');
                break;
            case 5:
                if (this.currentState !== 'boardReady') return;
                this.transitionToState('gameStartCountDown');
                break;
            default:
                console.log('Unknown event ID');
        }
    }

    // ------- handle coin events

    coinDetected = () => {
        // play coin detected sound
        console.log('coin detected');
        this.coinbox.audioPlayer.queueOrPlay('pling1', '/audio/wizards/pling1.mp3', 10, 'notification');
    }


    coinColorDetected = (event) => {
        const { action, colorname } = event;
        console.log('coin color detected', action, colorname);
        if (action !== 'coin') {
            return;
        }
        if (this.currentState !== 'payCost') return;
        if (this.currentCard === null) return;
        if (this.currentCard.manaCost === undefined) return;
        const index = this.currentCard.manaCost.indexOf(colorname);
        if (index === -1) return;
        this.currentCard.manaCost.splice(index, 1);
        console.log('missing cost:', this.currentCard.manaCost);
        if (this.currentCard.manaCost.length === 0) {
            if (this.currentCard.cardType === 'creature') {
                this.transitionToState('creaturePlayed');
                return;
            }
            if (this.currentCard.cardType === 'spell') {
                this.transitionToState('spellPlayed');
                return;
            }
        }

        this.updateCardState(this.currentCard);
        return;
    }

    updateCardState = (card) => {
        if (this.onCardStateChange) {
            this.onCardStateChange({
                ...card,
            });
        }
    }

    // ------- handle coin generator events

    coinGeneratorSetup = () => {
        this.coinbox.on('input', this.coinGeneratorRestart);
        for (const [id, data] of Object.entries(this.coinGeneration)) {
            data.colors = this.coinbox.random.shuffle([...data.colors]);
            this.coinbox.on(id, this.coinGenerator);
        }
    }

    coinGeneratorStart = () => {
        for (const [id] of Object.entries(this.coinGeneration)) {
            this.coinGeneratorAddCoin(id);
        }
    }

    coinGeneratorAtMaxCapacity = (color, maxCoins, tray) => {
        const coinsInPlay = this.coinbox.coinsInPlay(color, tray);
        return coinsInPlay >= maxCoins;
    }

    coinGeneratorIsRunning = (id) => {
        return this.coinbox.getAllFromOutputQueue().some(coin => coin.callbackName === id)
    }

    coinGeneratorInstantOutput = (id) => {
        const coin = this.coinbox.getAllFromOutputQueue().find(coin => coin.callbackName === id);
        //console.log('coinGeneratorInstantOutput:', coin);
        const now = Date.now();
        if (!coin || coin.timestamp <= now) return false;
        coin.timestamp = now;
        return true;
    }

    coinGeneratorAddCoin = (id) => {
        console.log('coinGeneratorAddCoin:', id);
        if (this.coinGeneratorIsRunning(id)) return false;
        const data = this.coinGeneration[id];
        const numberOfCoinsInPool = data.colors.length;
        console.log('coinGeneratorAddCoin - length:', numberOfCoinsInPool);
        let maxTries = numberOfCoinsInPool;
        while (maxTries > 0) {
            maxTries -= 1;
            const index = data.index;
            data.index = (index + 1) % numberOfCoinsInPool;
            const colorName = data.colors[index];
            const color = this.colorNames.indexOf(colorName);
            const capacity = data.colors.filter(c => c === colorName).length;
            if (this.coinGeneratorAtMaxCapacity(color, capacity, data.tray)) continue;
            let timeout = Date.now() + (data.firstNumberCoins > 0 ? data.firstdelay : data.delay);
            data.firstNumberCoins -= 1;
            if (timeout < Date.now()) timeout = Date.now() + 500;
            this.coinbox.addToOutputQueue(color, timeout, id, data.tray);
            console.log('coinGeneratorAddCoin - addToOutputQueue:', colorName, timeout, id, data.tray);
            return true;
        }
        return false;
    }

    coinGenerator = (event) => {
        this.coinGeneratorAddCoin(event.callbackName);
    }

    coinGeneratorRestart = (event) => {
        if (event.action === 'cancel') {
            console.log('cancel coin');
        } else if (event.action !== 'used') return;
        this.coinGeneratorStart();
    }

}

export default Wizards2Client;
