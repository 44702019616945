import React, { useState } from 'react';
import { auth } from '../firebase/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

const SignInWithEmail = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const signIn = async (e) => {
    e.preventDefault(); // Prevent form from submitting the traditional way
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('User signed in with email and password');
      setError(''); // Clear any previous errors
    } catch (error) {
      setError('Failed to sign in with email and password');
      console.error('Error signing in with email and password:', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <form onSubmit={signIn} className="w-full max-w-xs">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
          className="mb-4 p-2 w-full border rounded shadow"
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
          className="mb-4 p-2 w-full border rounded shadow"
        />
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full">
          Sign In
        </button>
      </form>
      {error && <p className="text-red-500 text-xs mt-4">{error}</p>}
    </div>

  );
};

export default SignInWithEmail;
