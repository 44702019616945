import TimedEvent from './TimedEvent';

class ServerlessClientBase {
  constructor(coinbox) {
    this.playerId = coinbox.coinboxId;
    this.state = null;
    this.player = '';
    this.coinbox = coinbox;
    this.gameId = null;
    this.timedEvents = [];

    setInterval(() => {
      if (this.coinbox.audioPlayer) {
        this.coinbox.audioPlayer.play('nosound', '/audio/pause_250.mp3', 98, 'notification');
      }
    }, 10000);

    this.colorNames = ['unknown', 'black', 'blue', 'brown', 'green', 'grey', 'orange', 'pink', 'purple', 'red', 'white', 'yellow'];
  }

  scheduleEvent(action, delay, data = null, callback = null) {
    const onComplete = (event) => {
      this.timedEvents = this.timedEvents.filter(e => e !== event);
    };
    const timedEvent = new TimedEvent(action, delay, data, callback, this.coinbox.audioPlayer, onComplete);
    timedEvent.start();
    this.timedEvents.push(timedEvent);
    return timedEvent;
  }

  removeEvent(event) {
    event.cancel();
    this.timedEvents = this.timedEvents.filter(e => e !== event);
  }

  cancelAllEvents() {
    this.timedEvents.forEach(event => event.cancel());
    this.timedEvents = [];
  }

  pauseAllEvents() {
    this.timedEvents.forEach(event => event.pause());
  }

  resumeAllEvents() {
    this.timedEvents.forEach(event => event.resume());
  }

  async pauseAndClear() {
    await this.coinbox.pause();
    this.coinbox.clearInputQueue();
    this.coinbox.clearOutputQueue();
    this.cancelAllEvents();
  }

  async pause() {
    await this.coinbox.pause();
  }

  async start() {
    await this.coinbox.start();
  }

}

export default ServerlessClientBase;
