class TimedEvent {
    constructor(action, delay, data, callback, audioPlayer, onComplete) {
      this.action = action;
      this.delay = delay;
      this.data = data;
      this.callback = callback;
      this.audioPlayer = audioPlayer;
      this.timeoutId = null;
      this.canceled = false;
      this.startTime = null; 
      this.remaining = delay; 
      this.onComplete = onComplete;
    }
  
    start() {
      this.canceled = false;
      this.startTime = Date.now();
      this.timeoutId = setTimeout(() => {
        this.execute();
      }, this.remaining);
    }
  
    execute() {
        Promise.resolve(this.action(this.data))
          .then(() => {
            if (this.callback && !this.canceled) {
              return this.callback();
            }
          })
          .finally(() => {
            this.cleanup();
          });
      }
  
    pause() {
        if(!this.timeoutId) return;
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
        this.remaining -= Date.now() - this.startTime;
    }
  
    resume() {
      if (!this.canceled && this.remaining > 0) {
        this.start();
      }
    }
  
    cancel() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.canceled = true;
      if (this.audioPlayer && this.data && this.data.audioId) {
        this.audioPlayer.stop(this.data.audioId);
      }
      this.cleanup();
    }
  
    cleanup() {
        this.timeoutId = null;
        this.startTime = null;
        this.remaining = this.delay;

        if (typeof this.onComplete === 'function') {
            this.onComplete(this);
        }
    }
  }
  
  export default TimedEvent;