import React, { useEffect, useState, useRef } from 'react';
import Wizards2Client from '../../game/wizards2/Wizards2Client';

const Wizards2ClientComponent = ({ coinbox }) => {
  const [gameState, setGameState] = useState("");
  const [cardState, setCardState] = useState([]);
  const isMounted = useRef(false);

  useEffect(() => {
    async function setupGame() {
      if (!coinbox) return;
      if (isMounted.current) return;
      isMounted.current = true;
      console.log('Wizards2:', coinbox);
      const newGameClient = new Wizards2Client(coinbox, setGameState, setCardState);
      console.log('Game client:', newGameClient);
      await newGameClient.start();
      await newGameClient.pause();
      //setGameClient(newGameClient);
    }

    setupGame();
  }, [coinbox]);


  return (
    <div className="p-4 w-full">
      {(gameState === 'setupGame') && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">Detecing Arena<br />Remove all creatures, spells and life</h2>
        </div>
      )}
      {(gameState === 'boardReady' ) && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">Place life cards with 10 life at the top<br/>Then place your hand in creature area 3 to start the game!</h2>
        </div>
      )}
       {(gameState === 'gameStartCountDown' ) && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">{cardState.count}</h2>
        </div>
      )}
       {(gameState === 'gameStart' ) && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">Starting!</h2>
        </div>
      )}
       {(gameState === 'realTime' ) && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">Game is running!<br />Play a card or wait for mana!</h2>
        </div>
      )}
        {(gameState === 'cardPlayed' ) && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">A Card has been played!</h2>
        </div>
      )}
        {(gameState === 'payCost' ) && (
        <div className="mb-4 p-4 w-full" style={{
          background: cardState.player === 'player1' ? 'red' : 'blue',
          color: 'white',
      }}>
          <h2 className="text-xl font-bold">{(cardState.name + ' ' ) + (cardState.cardType==='creature'?'has entered the battlefield!':'played!')}<br />Pay the Tower!</h2>
          <div className='mana'>
            {cardState.manaCost.map((mana, index) => (
              <div key={index} style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: mana,
                display: 'inline-block',
                margin: '3px',
                border: '1px solid black',
              }} />
            ))}
             </div>
             <img src={'/images/wizards2/'+cardState.name.toLowerCase().replace(' ', '_') +'.jpg'} alt={cardState.name} width="100%"/>
        </div>
      )}
       {(gameState === 'creaturePlayed' ) && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">Creature played</h2>
        </div>
      )}
       {(gameState === 'spellPlayed' ) && (
        <div className="mb-4 p-4 w-full" style={{
          background: cardState.player === 'player1' ? 'red' : 'blue',
          color: 'white',
      }}>
          <h2 className="text-xl font-bold">{cardState.name} is cast!<br />Take it from the spell area to continue the game!</h2>
        </div>
      )}
       {(gameState === 'resumeCountDown' ) && (
        <div className="mb-4 p-4 w-full">
          <h2 className="text-xl font-bold">Game will continue in {cardState.count}</h2>
        </div>
      )}
       {(gameState === 'waitForBoardToChange' ) && (
        <div className="mb-4 p-4 w-full" style={{
          background: cardState.player === 'player1' ? 'red' : 'blue',
          color: 'white',
      }}>
          <h2 className="text-xl font-bold">Attack with {cardState.name}</h2>
          <div>{cardState.boardStateChanged?"Change detected":""}</div>
          <img src={'/images/wizards2/'+cardState.name.toLowerCase().replace(' ', '_') +'.jpg'} alt={cardState.name} width="100%"/>
        </div>
      )}
       {(gameState === 'gameOver' ) && (
        <div className="mb-4 p-4 w-full" style={{
          background: cardState.won === 'player1' ? 'red' : 'blue',
          color: 'white',
      }}>
          <h2 className="text-xl font-bold">Winner!</h2>
        </div>
      )}
      </div>
  );
};

export default Wizards2ClientComponent;
